import { createSlice } from '@reduxjs/toolkit';

export enum OverlayModule {
  'CHAT',
  'TELEPHONY',
  'NOTIFICATIONS',
  'BOARDING_TIME_UPDATE',
  'ETD_SETTINGS',
  'BRIEFING_PACKAGE',
  'MERGE_BAGS',
}

interface OverlayState {
  openOverlay: OverlayModule;
}
const initialState = {
  openOverlay: null,
} as OverlayState;

export const overlayReducer = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    setOpenOverlay(state, action) {
      state.openOverlay = action.payload;
    },
  },
});

export const { setOpenOverlay } = overlayReducer.actions;

export const selectOpenOverlay = (state) => {
  return state.overlayReducer.openOverlay;
};

export default overlayReducer.reducer;
