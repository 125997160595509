import React from 'react';
import classNames from 'classnames';
import Title from '../../Title/Title';
import Grid from '../../Grid/Grid';
import Icon from '../../Icon/Icon';
import {
  FLIGHT_STATUS,
  FLIGHT_DETAILS,
  EMPTY_STATE,
  INITIAL_STATE,
  TOBT,
  TSAT,
  CTOT,
  NULL_TIME_STATE,
  FLIGHT_CANCELLED,
  SLOT_CANCELLED,
} from '../../../utils/constants';
import { capitalize } from '../../../utils/helpers';
import Badge from '../../Badge/Badge';
import useCheckPermissions, {
  CheckType,
} from '../../../utils/hooks/useCheckPermissions';
import { Permission } from '../../../utils/generated/graphql';
import { useSelector } from 'react-redux';
import { select24Format } from '../../../redux/reducers/settingsReducer';
import { BADGE_CONFIG } from '../../../utils/components_config';

const departureTimeClass =
  'font-body-thin text-[50px] leading-[55px] dark:text-grey-12 text-primary align-text-bottom';
const timeClass =
  'dark:text-grey-12 text-primary font-head-light mt-4 text-18 text-center text-nowrap';
const flightDetailContainer =
  'grid grid-cols-1 gap-y-16 mobile:w-full w-[350px] h-[450px] tablet:order-2 tablet:w-auto';

const delayClass = 'dark:text-red text-red text-18 absolute bottom-[3px]';
const dividerClass = 'w-[2px] h-auto bg-grey-12';
const timeClassName = 'text-18 text-grey-40';

interface IFlightStatus {
  flightData: {
    flightStatus: string;
    acceptance: string;
    fueling: string;
    boarding: string;
    ramp: string;
    loadControl: string;
    departureTime: {
      time: string;
      delay?: string;
    };
    targetOffBlockTime: { time: string; countDown: string };
    targetStartAprovalTime: { time: string; countDown: string };
    calculatedTakeOffTime: { time: string; countDown: string };
    countDown: string;
    timeLogicUsed?: string;
    mostPenalisingRegulationCause: {
      reason: string;
      locationCategory: string;
      iataDelayCode: string;
    };
  };
}

const renderHeader = (
  flightStatus: string,
  departureTime: {
    time: string;
    delay?: string;
  },
  targetOffBlockTime: { time: string; countDown: string },
  targetStartAprovalTime: { time: string; countDown: string },
  calculatedTakeOffTime: { time: string; countDown: string },
  countDown: string,
  timeLogicUsed: string,
  mostPenalisingRegulationCause: {
    reason: string;
    locationCategory: string;
    iataDelayCode: string;
  },
  disabled: boolean,
  is24Format: boolean = true
) => {
  const isDefaultStatus =
    flightStatus && flightStatus === FLIGHT_STATUS.DEFAULT;

  const isStatusOvercrowded =
    !!mostPenalisingRegulationCause?.reason && !isDefaultStatus;

  const headerClass = classNames(
    'flex flex-col justify-center items-center rounded-8 dark:bg-grey-90 bg-white p-24',
    {
      'opacity-50': disabled,
      'gap-y-[5px]': isStatusOvercrowded,
      'gap-y-[14px]': !isStatusOvercrowded,
    }
  );

  const reasonText =
    mostPenalisingRegulationCause.reason === FLIGHT_CANCELLED
      ? SLOT_CANCELLED
      : `Slot reason: ${mostPenalisingRegulationCause.reason}`;

  return (
    <div className={headerClass}>
      {!isDefaultStatus && <Badge {...BADGE_CONFIG[flightStatus]} />}
      <div className="flex max-w-fit justify-self-center items-end">
        <p className={timeClassName}>{`${timeLogicUsed} ${countDown}`}</p>
      </div>
      <div className="grid relative w-full">
        <div className="self-center text-center max-w-fit justify-self-center">
          <p
            className={classNames(departureTimeClass, {
              'w-[140px]': is24Format,
              'w-[200px]': !is24Format,
            })}>
            {departureTime.time}
          </p>
          {departureTime?.delay && (
            <p
              className={classNames(delayClass, {
                'ml-[140px]': is24Format,
                'ml-[200px]': !is24Format,
              })}>
              {departureTime.delay}m
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-[8px] max-w-fit justify-self-center">
        <Title
          className="w-[72px] text-center"
          title={`${TOBT}  ${targetOffBlockTime.countDown}`}>
          <p className={timeClass}>{targetOffBlockTime.time}</p>
        </Title>
        <hr className={dividerClass} />
        <Title
          className="w-[72px] text-center"
          title={`${TSAT} ${targetStartAprovalTime.countDown}`}>
          <p className={timeClass}>{targetStartAprovalTime.time}</p>
        </Title>
        <hr className={dividerClass} />
        <Title
          className="w-[72px] text-center"
          title={`${CTOT} ${calculatedTakeOffTime.countDown}`}>
          <p className={timeClass}>{calculatedTakeOffTime.time}</p>
        </Title>
      </div>
      {!!mostPenalisingRegulationCause?.reason && (
        <Badge
          Icon={
            <Icon
              width={12}
              height={12}
              variant="info"
              className="dark:fill-grey-12 fill-primary"
            />
          }
          text={reasonText}
          textClassName="font-body-text dark:text-grey-12 text-primary"
          className="dark:bg-grey-100 mt-[10px] bg-grey-6"
        />
      )}
    </div>
  );
};

const FlightStatus = ({
  flightData: {
    flightStatus,
    acceptance = INITIAL_STATE,
    fueling = INITIAL_STATE,
    boarding = INITIAL_STATE,
    ramp = INITIAL_STATE,
    loadControl = INITIAL_STATE,
    departureTime = { time: INITIAL_STATE },
    countDown = INITIAL_STATE,
    timeLogicUsed = INITIAL_STATE,
    targetOffBlockTime = { time: NULL_TIME_STATE, countDown: INITIAL_STATE },
    targetStartAprovalTime = {
      time: NULL_TIME_STATE,
      countDown: INITIAL_STATE,
    },
    calculatedTakeOffTime = { time: NULL_TIME_STATE, countDown: INITIAL_STATE },
    mostPenalisingRegulationCause = {
      reason: '',
      locationCategory: '',
      iataDelayCode: '',
    },
  },
  ...others
}: IFlightStatus) => {
  const isCancelled = flightStatus === FLIGHT_STATUS.CANCELLED;
  const titleColorClass = classNames({
    'text-grey-40': flightStatus === FLIGHT_STATUS.DEFAULT,
    'text-grey-25': flightStatus !== FLIGHT_STATUS.DEFAULT,
  });
  const subtitleClass = classNames(
    'text-18 font-head-light leading-[19px] mt-4 dark:text-grey-12 h-[19px]',
    {
      'text-primary': flightStatus === FLIGHT_STATUS.DEFAULT,
      'dark:text-grey-60 text-grey-40': flightStatus !== FLIGHT_STATUS.DEFAULT,
    }
  );

  const hasStatusDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_STATUS_DATA],
    CheckType.FULL
  );

  const hasTimesDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_TIMES_DATA],
    CheckType.FULL
  );

  const is24Format = useSelector(select24Format);

  return (
    <div className={flightDetailContainer} {...others}>
      {renderHeader(
        flightStatus,
        departureTime,
        targetOffBlockTime,
        targetStartAprovalTime,
        calculatedTakeOffTime,
        countDown,
        timeLogicUsed,
        mostPenalisingRegulationCause,
        !hasTimesDataPermission,
        is24Format
      )}
      <Grid
        col={[2, 16, 8]}
        className={classNames('p-32 rounded-8 dark:bg-grey-90 bg-white', {
          'opacity-50': !hasStatusDataPermission,
        })}>
        <Title
          titleColorClass={titleColorClass}
          title={FLIGHT_DETAILS.ACCEPTANCE}>
          <p className={subtitleClass}>
            {isCancelled || !acceptance ? EMPTY_STATE : capitalize(acceptance)}
          </p>
        </Title>
        <Title
          titleColorClass={titleColorClass}
          title={FLIGHT_DETAILS.BOARDING}>
          <p className={subtitleClass}>
            {isCancelled || !boarding ? EMPTY_STATE : capitalize(boarding)}
          </p>
        </Title>
        <Title titleColorClass={titleColorClass} title={FLIGHT_DETAILS.RAMP}>
          <p className={subtitleClass}>
            {isCancelled || !ramp ? EMPTY_STATE : capitalize(ramp)}
          </p>
        </Title>
        <Title titleColorClass={titleColorClass} title={FLIGHT_DETAILS.FUELING}>
          <p className={subtitleClass}>
            {isCancelled || !fueling ? EMPTY_STATE : capitalize(fueling)}
          </p>
        </Title>
        <Title
          titleColorClass={titleColorClass}
          className="col-span-2"
          title={FLIGHT_DETAILS.LOAD_CONTROL}>
          <p className={subtitleClass}>
            {isCancelled || !loadControl
              ? EMPTY_STATE
              : capitalize(loadControl)}
          </p>
        </Title>
      </Grid>
    </div>
  );
};

export default FlightStatus;
