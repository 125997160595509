import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import {
  FLIGHT_STATUS,
  FLIGHT_BLOCK_COPY,
  EMPTY_STATE,
} from '../../../utils/constants';
import Badge from '../../Badge/Badge';
import Grid from '../../Grid/Grid';
import Icon from '../../Icon/Icon';
import PulseNotificationIcon from '../../PulseNotificationIcon/PulseNotificationIcon';
import Tailwing from '../../Tailwing/Tailwing';
import Title from '../../Title/Title';
import ProgressBar from './ProgressBar/ProgressBar';
import NotificationsOverlay from './NotificationOverlay/NotificationOverlay';
import { BADGE_CONFIG, ERROR_BADGE } from '../../../utils/components_config';
import useCountdown from '../../../utils/hooks/useCountdown';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectFavoriteFlightsMuted,
  selectUTC,
} from '../../../redux/reducers/settingsReducer';
import { calculateTime } from '../../../utils/calculateTime';
import { MyFlight } from '../../../utils/generated/graphql';
import { IMyFlightEdit } from '../../../pages/Favourites/Favourites';
import { getAirlineKey } from '../../../utils/helpers';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';
import FeatureFlagUtil from '../../../utils/FeatureFlagUtil';

const badgeClass = 'dark:bg-grey-100 bg-grey-6 w-[39px] h-[24px]';
const badgeTextClass = 'dark:text-grey-12 text-primary font-[400] text-12';
const iconClass = 'flex self-center mx-8 dark:fill-grey-12';
const flightTitleContainer = 'flex mb-[19px] justify-between';
const flightTitleClass =
  'dark:text-grey-12 font-head-bold font-[700] text-40 text-primary leading-[40px]';
const originDestinationClass =
  'dark:text-grey-12 text-primary font-head-light font-[300] text-18 leading-[19px]';
const subTitleClass =
  'text-primary dark:text-grey-12 leading-[19px] font-[300] text-18 font-head-light';
const delayClass = 'font-[400] text-red dark:text-red text-12 leading-[12px]';
const titleColorClass = 'dark:text-grey-40 text-grey-40';
const titleClass = 'max-h-[37px]';
const errorClass = 'flex justify-center my-[13px]';
const routeClass = 'flex items-center h-24';

const MY_FLIGHTS_2_ENABLED = FeatureFlagUtil.showFeature(
  process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
  []
);

export interface IFlightDataObject extends MyFlight {
  checkedIn?: boolean;
  tags?: string;
  checkedInRole?: string | null;
}

interface IFlightBlockProps {
  flightData: IFlightDataObject;
  isFromHomepage?: boolean;
  isEditable?: boolean;
  isMuted?: boolean;
  error?: string | null;
  flightListEdit: IMyFlightEdit[];
  setFlightListEdit: Dispatch<SetStateAction<IMyFlightEdit[]>>;
  addFlightOnMuteList?: (flight: string) => void;
  addFlightOnRemoveList?: (flight: string) => void;
}

const FlightBlock = ({
  flightData: {
    flightId,
    countdown,
    pos,
    flightStatus,
    flightName,
    isCheckedIn = false,
    origin,
    destination,
    type,
    gate,
    registration,
    processes,
    tags,
    departureTimes,
    notificationMuted = false,
    isFavourite,
    role,
    checkedInRole,
  },
  isFromHomepage = false,
  isEditable = false,
  error = null,
  flightListEdit,
  setFlightListEdit,
  addFlightOnMuteList,
  addFlightOnRemoveList,
  ...others
}: IFlightBlockProps) => {
  const timeLeft = useCountdown(countdown?.timeLeft ?? '');
  const useUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const isFavouriteFlightsMuted = useSelector(selectFavoriteFlightsMuted);
  const time = calculateTime(useUTC, is24Format, departureTimes) ?? EMPTY_STATE;
  const [isFavouriteLocally, setIsFavouriteLocally] = useState(
    MY_FLIGHTS_2_ENABLED ? isFavourite : true
  );
  const defaultMutedState = isFavourite
    ? notificationMuted
    : isFavouriteFlightsMuted;
  const [isMutedLocally, setIsMutedLocally] = useState(
    MY_FLIGHTS_2_ENABLED ? defaultMutedState : notificationMuted
  );
  const isOnline = useNetworkStatus();

  if ('20240201LH-00108-FRA-MUC' === flightId)
    console.log('isMutedLocally', isMutedLocally);

  useEffect(() => {
    setIsMutedLocally(defaultMutedState);
    if (!isEditable) {
      setIsFavouriteLocally(isFavourite);
    }
  }, [isEditable, defaultMutedState]);

  const flightBlockContainer = classNames(
    'cursor-pointer justify-self-center flex flex-col justify-between w-[350px] mobile:w-[342px] rounded-8 bg-white dark:bg-grey-90',
    {
      'h-[511px]': !isFromHomepage,
      'h-[551px]': isFromHomepage,
    }
  );

  const navigate = useNavigate();
  const timeClass = classNames(
    'max-w-[157px] min-w-[157px] h-[124px] flex items-center font-body-thin text-64 leading-[64px]',
    {
      'dark:text-grey-12 text-primary': !countdown?.delay,
      'dark:text-red text-red': countdown?.delay,
    },
    { 'opacity-0': flightStatus !== FLIGHT_STATUS.DEFAULT }
  );
  const flightStatusContainer = classNames('flex items-center', {
    'mb-[50px]': !error,
  });

  const airLineKey = getAirlineKey(flightName);

  return (
    <div
      className={classNames(
        flightBlockContainer,
        classNames({
          'cursor-none pointer-events-none': !isOnline,
        })
      )}
      {...others}
      onClick={() =>
        isEditable ? null : navigate(`/favourites/${flightId}/process`)
      }>
      <div className="pl-32 pt-32 pr-16">
        <div className={flightTitleContainer}>
          <div className="flex">
            <h1 className={flightTitleClass}>{flightName}</h1>
            {FeatureFlagUtil.showFeature(
              process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
              []
            ) &&
              isCheckedIn && <PulseNotificationIcon className="ml-10" />}
          </div>
          {tags && (
            <Badge
              className={badgeClass}
              text={tags}
              textClassName={badgeTextClass}
            />
          )}
        </div>
        <div className={flightStatusContainer}>
          <div className={routeClass}>
            <p className={originDestinationClass}>{origin}</p>
            <Icon variant="arrow" className={iconClass} />
            <p className={originDestinationClass}>{destination}</p>
            {notificationMuted && (
              <Icon
                variant="unmute"
                className="ml-16 dark:fill-white"
                height={20}
                width={20}
              />
            )}
          </div>
          {flightStatus && flightStatus !== FLIGHT_STATUS.DEFAULT && (
            <div className="ml-16">
              <Badge {...BADGE_CONFIG[flightStatus]} />
            </div>
          )}
        </div>
        {error && (
          <div className={errorClass}>
            <Badge {...ERROR_BADGE(error)} />
          </div>
        )}
        <div className="relative">
          <NotificationsOverlay
            isOpen={isEditable}
            isMutedLocally={!!isMutedLocally}
            isFavourite={!!isFavouriteLocally}
            onMuteNotification={() => {
              setIsMutedLocally((prev) => !prev);
              if (MY_FLIGHTS_2_ENABLED) {
                setFlightListEdit((prevFlightList) =>
                  prevFlightList.map((flight) =>
                    flight.flightId === flightId
                      ? {
                          ...flight,
                          notificationMuted: !flight.notificationMuted,
                        }
                      : flight
                  )
                );
              } else {
                addFlightOnMuteList?.(flightId ?? '');
              }
            }}
            onChangeFavourite={() => {
              setIsFavouriteLocally((prev) => !prev);
              if (MY_FLIGHTS_2_ENABLED) {
                setFlightListEdit((prevFlightList) =>
                  prevFlightList.map((flight) =>
                    flight.flightId === flightId
                      ? {
                          ...flight,
                          isFavourite: !flight.isFavourite,
                          notificationMuted:
                            isMutedLocally ?? flight.notificationMuted,
                        }
                      : flight
                  )
                );
              } else {
                addFlightOnRemoveList?.(flightId ?? '');
              }
            }}
            flightId={flightId ?? ''}
            isNotificationDisabled={MY_FLIGHTS_2_ENABLED && !isFavouriteLocally}
            role={role?._id ?? checkedInRole}
            onCheckinEdit={(role: string | null) => {
              setFlightListEdit((prevFlightList) =>
                prevFlightList.map((flight) =>
                  flight.flightId === flightId
                    ? {
                        ...flight,
                        checkedInRole: role,
                        isCheckedIn: !!role,
                      }
                    : flight
                )
              );
            }}
          />
          <Grid
            col={[2, 118, 44]}
            className={classNames({
              'mobile:mb-[38px] mb-[68px]': isFromHomepage,
            })}>
            <Title
              className={titleClass}
              title={FLIGHT_BLOCK_COPY.TYPE}
              titleColorClass={titleColorClass}>
              <p className={subTitleClass}>{type || EMPTY_STATE}</p>
            </Title>
            <Title
              className={titleClass}
              title={FLIGHT_BLOCK_COPY.REGISTRATION}
              titleColorClass={titleColorClass}>
              <p className={subTitleClass}>{registration || EMPTY_STATE}</p>
            </Title>
            <Title
              className={titleClass}
              title={FLIGHT_BLOCK_COPY.GATE}
              titleColorClass={titleColorClass}>
              <p className={subTitleClass}>{gate || EMPTY_STATE}</p>
            </Title>
            <Title
              className={titleClass}
              title={FLIGHT_BLOCK_COPY.POS}
              titleColorClass={titleColorClass}>
              <>
                <p className={subTitleClass}>{pos || EMPTY_STATE}</p>
              </>
            </Title>
            <Title
              className={titleClass}
              title={countdown?.timeLogicUsed || ''}
              titleColorClass={titleColorClass}>
              <p className={subTitleClass}>{time || EMPTY_STATE}</p>
              {!!countdown?.delay && (
                <p className={delayClass}>+{countdown.delay}min</p>
              )}
            </Title>
          </Grid>
        </div>
      </div>
      <div>
        <div className="flex justify-center">
          <p className={timeClass}>{timeLeft}</p>
          <Tailwing
            isOpaque={flightStatus !== FLIGHT_STATUS.DEFAULT && !isEditable}
            airlineKey={airLineKey ?? ''}
          />
        </div>
        <ProgressBar
          completedProcesses={
            flightStatus !== FLIGHT_STATUS.DEFAULT ? 0 : processes ?? 0
          }
          isDelayed={!!countdown?.delay}
        />
      </div>
    </div>
  );
};

export default FlightBlock;
