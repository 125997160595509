import { DEVICE_TYPE } from './constants';
import { Permission } from './generated/graphql';
import { checkCUTEDevice } from './helpers';
import useCheckPermissions, { CheckType } from './hooks/useCheckPermissions';
import { useDeviceBreakpoint } from './hooks/useDeviceBreakpoint';

export const useShowBagMerger = (
  currentRoleId: string,
  aircraftType: string,
  station: string
) => {
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  const isCUTEDevice = checkCUTEDevice();

  const inscopeAircraftTypes =
    process?.env?.REACT_APP_TAC_BAG_MERGER_INSCOPE_TYPES?.replace(
      /\s+/g,
      ''
    ).split(',') ?? [];

  const inscopeBagMergerStations =
    process?.env?.REACT_APP_TAC_BAG_MERGER_INSCOPE_STATIONS?.replace(
      /\s+/g,
      ''
    ).split(',') ?? [];

  const hasBagMergerPermission = useCheckPermissions(
    [Permission.UPDATE_LOAD_PLAN],
    CheckType.FULL,
    [currentRoleId]
  );

  return (
    hasBagMergerPermission &&
    inscopeAircraftTypes.includes(aircraftType) &&
    inscopeBagMergerStations.includes(station) &&
    !isMobile &&
    !isCUTEDevice
  );
};
