import { useQuery, useLazyQuery } from '@apollo/client';
import { Query, FlightCheckinRolesOutput } from '../generated/graphql';
import { IDataResponse } from '../types';
import { GET_CHECKED_IN_ROLES } from '../../graphql/checkInFlight/checked-in-roles.queries.ts';
import { isUserLoggedIn } from '../helpers.js';
import { useSelector } from 'react-redux';
import { selectSettingsData } from '../../redux/reducers/settingsReducer.ts';

export type FlightCheckinRoles = FlightCheckinRolesOutput | null;
interface IGetCheckedInRoles extends IDataResponse<FlightCheckinRoles[]> {
  refetchCheckInRoles: () => Promise<FlightCheckinRoles[]>;
}

interface IGetCheckedInRolesLazy extends IDataResponse<FlightCheckinRoles[]> {
  getCheckInRoles: (
    flightId: string,
    flightName?: string
  ) => Promise<FlightCheckinRoles[]>;
}

export const useGetCheckedInRoles = (
  flightId: string = '',
  flightName?: string
): IGetCheckedInRoles => {
  const isLoggedIn = isUserLoggedIn();
  const isLoginFlowComplete =
    useSelector(selectSettingsData).isLoginFlowComplete;
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'flightCheckedInRoles'>
  >(GET_CHECKED_IN_ROLES, {
    variables: {
      flightId,
      flightName,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !isLoggedIn || !isLoginFlowComplete,
  });

  const refetchCheckInRoles = async () => {
    const result = await refetch();
    return result?.data?.flightCheckedInRoles ?? [];
  };

  return {
    data: data?.flightCheckedInRoles ?? [],
    loading,
    error,
    refetchCheckInRoles,
  };
};

export const useGetCheckedInRolesLazy = (): IGetCheckedInRolesLazy => {
  const [onGetCheckedInRoles, { data, loading, error }] = useLazyQuery<
    Pick<Query, 'flightCheckedInRoles'>
  >(GET_CHECKED_IN_ROLES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const getCheckInRoles = async (flightId: string, flightName?: string) => {
    const result = await onGetCheckedInRoles({
      variables: {
        flightId,
        flightName,
      },
    });

    return result?.data?.flightCheckedInRoles ?? [];
  };

  return {
    data: data?.flightCheckedInRoles ?? [],
    loading,
    error,
    getCheckInRoles,
  };
};
