import React from 'react';
import classNames from 'classnames';
import Icon from '../../../Icon/Icon';
import Button from '../../../Button/Button';
import { EMPTY_STATE } from '../../../../utils/constants';
import PulseNotificationIcon from '../../../PulseNotificationIcon/PulseNotificationIcon';
import FeatureFlagUtil from '../../../../utils/FeatureFlagUtil';

interface IMenuHeaderFlight {
  flightName: string;
  time: string | null;
  departureAirport: string;
  arrivalAirport: string;
  type: string;
  registration: string;
  onMenuClick: () => void;
  themeColor: string | null;
  isCheckedIn?: boolean;
}

const containerClassNames =
  'pl-24 pr-[27px] leading-[19px] dark:bg-grey-90 dark:border-b-2 dark:border-black';
const topHeaderClassNames = 'h-56 flex justify-between py-16';
const bottomHeaderClassNames = 'h-[28px] flex gap-x-[22px] pb-[9px] ';
const airportsClassNames = 'flex items-center';
const flightNameClassNames = 'font-head-bold text-white text-18 flex gap-8';
const itemClassNames = 'font-body-thin text-white text-18';
const menuContainerClassNames = 'flex gap-[19px]';

const MenuHeaderFlight = ({
  flightName,
  time,
  departureAirport,
  arrivalAirport,
  type,
  registration,
  onMenuClick,
  themeColor,
  isCheckedIn = false,
  ...others
}: IMenuHeaderFlight) => {
  const iconClassNames = classNames('fill-white', {
    'dark:fill-grey-80': themeColor !== 'bg-primary',
  });

  return (
    <div className={classNames(containerClassNames, themeColor)} {...others}>
      <div className={topHeaderClassNames}>
        <div className={flightNameClassNames}>
          {flightName}
          {FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
            []
          ) &&
            isCheckedIn && <PulseNotificationIcon />}
        </div>
        <div className={menuContainerClassNames}>
          <div className={itemClassNames}>{time}</div>
          <Button
            Icon={<Icon variant="menu" className={iconClassNames} />}
            onClick={() => onMenuClick?.()}
          />
        </div>
      </div>
      <div className={bottomHeaderClassNames}>
        <div className={airportsClassNames}>
          <div className={itemClassNames}>{departureAirport}</div>
          <Icon variant="arrow" className="fill-white ml-3 mr-8" />
          <div className={itemClassNames}>{arrivalAirport}</div>
        </div>
        {(type || registration) && (
          <div className={itemClassNames}>{`${type || EMPTY_STATE}/${
            registration || EMPTY_STATE
          }`}</div>
        )}
      </div>
    </div>
  );
};

export default MenuHeaderFlight;
