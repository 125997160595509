import { useEffect } from 'react';
import {
  addError,
  addSuccess,
  addWarning,
  clearNotifications,
} from '../../redux/reducers/notificationReducer';
import { useDispatch } from 'react-redux';
import useNetworkStatus from './useNetworkStatus';

const OfflineMessage = process?.env?.REACT_APP_TAC_OFFLINE_MESSAGE ?? '';

export const useNotifications = (error, errorMessage) => {
  const dispatch = useDispatch();
  const isOnline = useNetworkStatus();

  useEffect(() => {
    if (!isOnline) {
      dispatch(clearNotifications());
      dispatch(addWarning(OfflineMessage));
    }
  }, [dispatch, isOnline]);

  useEffect(() => {
    return () => {
      isOnline && dispatch(clearNotifications());
    };
  }, [dispatch, isOnline]);

  useEffect(() => {
    if (isOnline) {
      if (error) {
        dispatch(addError(errorMessage));
      } else {
        dispatch(clearNotifications());
      }
    }
  }, [dispatch, error, errorMessage, isOnline]);

  return { addError, addSuccess, clearNotifications };
};
