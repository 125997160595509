import React from 'react';
import classNames from 'classnames';
import Icon from '../../Icon/Icon';
import PulseNotificationIcon from '../../PulseNotificationIcon/PulseNotificationIcon';
import Badge from '../../Badge/Badge';
import Title from '../../Title/Title';
import FavouriteButton from '../../CustomButtons/FavouriteButton/FavouriteButton';
import { BADGE_CONFIG } from '../../../utils/components_config';
import { FLIGHT_SEARCH } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectUTC,
} from '../../../redux/reducers/settingsReducer';
import { calculateTime } from '../../../utils/calculateTime';
import { IFlight } from '../types';
import { ITimesObject } from '../../../utils/types';
import useCheckPermissions from '../../../utils/hooks/useCheckPermissions';
import { Permission } from '../../../utils/generated/graphql';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';
import FeatureFlagUtil from '../../../utils/FeatureFlagUtil';

const centerVertically = 'flex items-center';
const containerClassNames =
  'mobile:mx-24 mobile:max-h-[186px] max-h-[76px] cursor-pointer bg-white rounded-8 flex justify-between items-center py-16 px-24 mobile:flex-wrap mobile:relative mobile:py-24 dark:bg-grey-90';
const flightDetailsClassNames = 'flex mobile:flex-col mobile:gap-16';
const titleClassNames =
  'w-[227px] font-head-bold text-30 text-primary leading-8 dark:text-grey-12 flex gap-4';
const notificationClassNames = classNames('w-16', centerVertically);
const destinationClassNames =
  'font-head-light text-18 text-primary dark:text-grey-12';
const departureDetailsClassNames =
  'flex mobile:w-full mobile:mt-24 mobile:justify-between';
const subtitleClassNames =
  'font-head-light text-primary text-18 dark:text-grey-12 whitespace-nowrap';
const extraDetailsClassNames = 'inline ml-8 text-12 mobile:block mobile:m-0';
const departureItemClassNames = 'w-90 mobile:w-fit';
const toffClassNames = 'w-[130px] mobile:w-fit';
const bagdeClassNames = classNames(centerVertically, 'w-86');

interface IDepartureTimes extends ITimesObject {
  bestTimeUTC12formatts: string;
}

interface IFlightItemProps extends IFlight {
  departureTimes?: IDepartureTimes;
  onFavouritePress: () => void;
  setUpdatedFlights: () => void;
}

const FlightItem = ({
  flightId,
  flightDetails: { flightName, departureAirport, arrivalAirport, flightStatus },
  departureDetails: { type, gate, position, departureTime },
  isFavourite = false,
  isCheckedIn = false,
  departureTimes,
  onFavouritePress,
  setUpdatedFlights,
  ...others
}: IFlightItemProps) => {
  const navigate = useNavigate();
  const hasMyFlightsAccess = useCheckPermissions([
    Permission.MANAGE_MY_FLIGHTS,
  ]);
  const isOnline = useNetworkStatus();

  const { timeLogicUsed, delay } = departureTime || {};
  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const time = calculateTime(isUTC, is24Format, departureTimes);

  const handleOnClick = () => {
    setUpdatedFlights();
  };
  return (
    <div
      className={classNames(containerClassNames, {
        'cursor-none pointer-events-none': !isOnline,
      })}
      {...others}
      onClick={() => navigate(`/search/${flightId}/process`)}>
      <div className={flightDetailsClassNames}>
        <div className={titleClassNames}>
          {flightName}
          {FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED || '',
            []
          ) &&
            isCheckedIn && (
              <div className={notificationClassNames}>
                <PulseNotificationIcon />
              </div>
            )}
        </div>
        <div className="flex gap-24 mobile:gap-16 ">
          <div className={classNames(centerVertically, 'w-[100px]')}>
            <div className={destinationClassNames}>{departureAirport}</div>
            <div className="px-8">
              <Icon variant="arrow" className="fill-black dark:fill-grey-12" />
            </div>
            <div className={destinationClassNames}>{arrivalAirport}</div>
          </div>
          <div className={bagdeClassNames}>
            <Badge {...BADGE_CONFIG[flightStatus]} />
          </div>
        </div>
      </div>
      <div className={departureDetailsClassNames}>
        <Title title={FLIGHT_SEARCH.TYPE} className={departureItemClassNames}>
          <div className={subtitleClassNames}>{type || '-'}</div>
        </Title>
        <Title title={FLIGHT_SEARCH.GATE} className={departureItemClassNames}>
          <div className={subtitleClassNames}>{gate || '-'}</div>
        </Title>
        <Title
          title={FLIGHT_SEARCH.POSITION}
          className={departureItemClassNames}>
          <div className={subtitleClassNames}>
            {position.value || '-'}
            {position.extraDetails && (
              <div className={extraDetailsClassNames}>
                {position?.extraDetails}
              </div>
            )}
          </div>
        </Title>
        <Title title={timeLogicUsed || ''} className={toffClassNames}>
          <div className={subtitleClassNames}>
            {time}
            {!!delay && (
              <div className={classNames(extraDetailsClassNames, 'text-red')}>
                +{delay}min
              </div>
            )}
          </div>
        </Title>
        {hasMyFlightsAccess && (
          <div className="mobile:absolute mobile:right-24 mobile:top-24">
            <FavouriteButton
              onClick={handleOnClick}
              favourite={isFavourite}
              disabled={!isOnline}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FlightItem;
