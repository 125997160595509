import React from 'react';
import { ABOVE_WING, BELOW_WING, GAUGE_LABEL } from '../../utils/constants';
import FlightStatus from './FlightStatus/FlightStatus';
import GaugeContainer from './GaugeContainer/GaugeContainer';
import { useNavigate } from 'react-router-dom';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { Permission, ProcessTime } from '../../utils/generated/graphql';
import classNames from 'classnames';
import ProcessList from './ProcessList';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';
import { selectRoles } from '../../redux/reducers/settingsReducer';
import { useSelector } from 'react-redux';

const flightDetailContainer =
  'flex flex-row-reverse tablet:grid tablet:grid-cols-3 mobile:flex-col justify-center gap-16 mobile:gap-24 mt-32';

interface IFlightDetail {
  flightData: {
    flightStatus: string;
    acceptance: string;
    fueling: string;
    boarding: string;
    ramp: string;
    loadControl: string;
    departureTime: {
      time: string;
      delay: string;
    };
    countDown: string;
    targetOffBlockTime: { time: string; countDown: string };
    targetStartAprovalTime: { time: string; countDown: string };
    calculatedTakeOffTime: { time: string; countDown: string };
    mostPenalisingRegulationCause: {
      reason: string;
      locationCategory: string;
      iataDelayCode: string;
    };
  };
  passengerData: {
    value: number;
    max: number;
    tagArray: {
      isWarning: boolean;
      title: string;
    }[];
    tagsShown: number;
  };
  baggageData: {
    value: number;
    max: number;
    tagArray: {
      isWarning: boolean;
      title: string;
    }[];
    tagsShown: number;
  };
  flightId: string;
  redirectSource: string;
  isLoading: boolean;
  processList: [
    {
      id: number;
      name: string;
      targetStart: ProcessTime;
      targetEnd: ProcessTime;
      actualStart: ProcessTime;
      actualEnd: ProcessTime;
    }
  ];
}

const FlightDetail = ({
  flightData,
  passengerData,
  baggageData,
  flightId,
  redirectSource,
  isLoading,
  processList,
  ...others
}: IFlightDetail) => {
  const navigate = useNavigate();
  const roles = useSelector(selectRoles);

  const hasAboveWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_ABOVE_WING_DATA],
    CheckType.FULL
  );

  const hasBelowWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_BELOW_WING_DATA],
    CheckType.FULL
  );

  const aboveWingClass = classNames('order-1 mobile:order-none tablet:w-auto', {
    'opacity-50 cursor-none pointer-events-none': !hasAboveWingDataPermission,
  });

  const belowWingClass = classNames('tablet:w-auto', {
    'opacity-50 cursor-none pointer-events-none': !hasBelowWingDataPermission,
  });

  return (
    <div className={flightDetailContainer} {...others}>
      {FeatureFlagUtil.showFeature(
        process?.env?.REACT_APP_TAC_TURNAROUND_PROCESS_FEATURE_DISABLED ?? '',
        roles
      ) && <ProcessList processList={processList} />}
      <FlightStatus flightData={flightData} />
      <GaugeContainer
        title={ABOVE_WING}
        label={GAUGE_LABEL.PASSENGERS}
        status={flightData.flightStatus}
        value={passengerData.value}
        max={passengerData.max}
        tagArray={passengerData.tagArray}
        className={aboveWingClass}
        tagsShown={passengerData.tagsShown}
        handleOnClick={() =>
          flightId ? navigate(`/${redirectSource}/${flightId}/above`) : null
        }
        isLoading={isLoading}
      />
      <GaugeContainer
        title={BELOW_WING}
        label={GAUGE_LABEL.BAGGAGES}
        status={flightData.flightStatus}
        value={baggageData.value}
        max={baggageData.max}
        tagArray={baggageData.tagArray}
        tagsShown={baggageData.tagsShown}
        handleOnClick={() =>
          flightId ? navigate(`/${redirectSource}/${flightId}/below`) : null
        }
        isLoading={isLoading}
        className={belowWingClass}
      />
    </div>
  );
};

export default FlightDetail;
