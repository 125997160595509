import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../Icon/Icon';
import Button from '../../../Button/Button';

interface IMenuHeader {
  themeColor?: string | null;
  hasBackArrow: boolean;
  title?: string;
  onMenuClick: () => void;
  className?: string;
  onBackArrowClick?: () => void;
}

const titleClassNames =
  'font-head-light text-grey-60 text-18 dark:text-grey-40';

const MenuHeader = ({
  themeColor,
  hasBackArrow = false,
  title,
  onMenuClick,
  className,
  onBackArrowClick,
  ...others
}: IMenuHeader) => {
  const navigate = useNavigate();

  const containerClassNames = classNames(
    'h-56 flex justify-between px-[27px] py-[20px] leading-[18px] z-50',
    className,
    {
      'bg-grey-6 border-b-1 border-grey-12 dark:bg-black dark:border-grey-80':
        !themeColor,
      [themeColor as string]: themeColor,
    }
  );
  const iconClassNames = classNames('fill-primary dark:fill-grey-12', {
    'fill-white1': themeColor,
  });

  return (
    <div className={containerClassNames} {...others}>
      <div>
        {hasBackArrow && (
          <Button
            Icon={
              <Icon
                width={18}
                height={18}
                variant="arrowBack"
                className={iconClassNames}
              />
            }
            onClick={() => navigate(-1)}
            data-testid="back-button"
          />
        )}
      </div>
      <div>{title && <div className={titleClassNames}>{title}</div>}</div>
      <div>
        <Button
          Icon={<Icon variant="menu" className={iconClassNames} />}
          onClick={() => onMenuClick?.()}
          data-testid="menu-button"
        />
      </div>
    </div>
  );
};

export default MenuHeader;
