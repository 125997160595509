import React from 'react';
import ProcessItem from './ProcessItem';
import { GanttData } from '../../utils/generated/graphql';
import { NO_PROCESSES_AVAILABLE } from '../../utils/constants';

interface IProcessList {
  processList: GanttData[];
}

const containerClass =
  'z-[1] flex flex-col dark:bg-grey-90 bg-white rounded-8 tablet:w-auto w-[350px] min-h-[450px] p-15 mobile:order-last mobile:w-full tablet:order-last';

const ProcessList: React.FC<IProcessList> = ({ processList }: IProcessList) => {
  return (
    <div className={containerClass}>
      {processList && processList.length > 0 ? (
        processList.map((process) => (
          <ProcessItem
            key={process.id.toString()}
            name={process.name}
            targetStart={process.targetStart}
            targetEnd={process.targetEnd}
            actualStart={process.actualStart}
            actualEnd={process.actualEnd}
          />
        ))
      ) : (
        <div className="h-full flex items-center justify-center text-center text-grey-40 dark:text-grey-12">
          {NO_PROCESSES_AVAILABLE}
        </div>
      )}
    </div>
  );
};

export default ProcessList;
