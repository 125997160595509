import classNames from 'classnames';
import React from 'react';
import { NOTIFICATION_BUTTON } from '../../../../utils/constants';
import Button from '../../../Button/Button';
import FavouriteButton from '../../../CustomButtons/FavouriteButton/FavouriteButton';
import Icon from '../../../Icon/Icon';
import './NotificationOverlay.scss';
import CheckinContainer from '../../../FlightDetailsHeader/CheckinDropdown/CheckinContainer';
import { getFlightName } from '../../../../utils/helpers';
import useCheckPermissions, {
  CheckType,
} from '../../../../utils/hooks/useCheckPermissions';
import { Permission } from '../../../../utils/generated/graphql';
import FeatureFlagUtil from '../../../../utils/FeatureFlagUtil';

interface INotificationOverlayProps {
  isOpen?: boolean;
  isMutedLocally?: boolean;
  isFavourite?: boolean;
  onMuteNotification?: () => void;
  onChangeFavourite?: () => void;
  flightId: string;
  isNotificationDisabled: boolean;
  role?: string | null;
  onCheckinEdit: (role: string | null) => void;
}

export const NotificationsOverlay = ({
  isOpen = false,
  isMutedLocally,
  isFavourite = true,
  onMuteNotification,
  onChangeFavourite,
  flightId,
  isNotificationDisabled,
  role,
  onCheckinEdit,
  ...others
}: INotificationOverlayProps) => {
  const hasCheckInPermission = useCheckPermissions(
    [Permission.CHECK_IN],
    CheckType.PARTIAL
  );

  const MY_FLIGHTS_2_ENABLED = FeatureFlagUtil.showFeature(
    process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED || '',
    []
  );

  const muteButtonCommonClass = classNames(
    'flex items-center w-[222px] h-44 rounded-4',
    {
      'justify-evenly ml-[13px]': !MY_FLIGHTS_2_ENABLED,
      'justify-center gap-24': MY_FLIGHTS_2_ENABLED,
    }
  );
  const muteButtonClass = classNames(muteButtonCommonClass, 'bg-primary');
  const muteTextClass = 'text-white font-head-bold text-14 leading-[18px]';
  const unmuteButtonClass = classNames(
    muteButtonCommonClass,
    'border-[1px] border-primary bg-white'
  );
  const disabledButtonClass = classNames(
    muteButtonCommonClass,
    'bg-grey-40 border-0'
  );
  const unmuteTextClass =
    'text-primary font-head-bold text-14 leading-[18px] dark:text-primary';
  const disabledTextClass = 'text-white';

  return (
    <>
      {isOpen && (
        <div
          className={classNames('dark:bg-grey-90 dark:bg-opacity-90 flex', {
            overlay__container: !MY_FLIGHTS_2_ENABLED,
            overlay__container_v2: MY_FLIGHTS_2_ENABLED,
          })}
          {...others}>
          <Button
            Icon={
              <Icon
                variant={
                  isMutedLocally || isNotificationDisabled ? 'unmute' : 'mute'
                }
                className={
                  !isMutedLocally || isNotificationDisabled
                    ? 'fill-white'
                    : 'fill-primary'
                }
              />
            }
            text={
              isMutedLocally || isNotificationDisabled
                ? NOTIFICATION_BUTTON.UNMUTE
                : NOTIFICATION_BUTTON.MUTE
            }
            className={classNames({
              'w-full': MY_FLIGHTS_2_ENABLED,
              [unmuteButtonClass]: isMutedLocally && !isNotificationDisabled,
              [muteButtonClass]: !isMutedLocally && !isNotificationDisabled,
              [disabledButtonClass]: isNotificationDisabled,
            })}
            textClassName={classNames({
              [unmuteTextClass]: isMutedLocally,
              [muteTextClass]: !isMutedLocally,
              [disabledTextClass]: isNotificationDisabled,
            })}
            onClick={onMuteNotification}
            disabled={isNotificationDisabled}
          />
          <div className={'flex justify-around gap-16 relative z-10'}>
            {MY_FLIGHTS_2_ENABLED && (
              <CheckinContainer
                flightName={getFlightName(flightId)}
                flightId={flightId}
                loading={false}
                variant="outlined"
                className="flex-1 bg-white z-40"
                checkedInRole={role}
                editModeEnabled={true}
                onEdit={onCheckinEdit}
              />
            )}
            <FavouriteButton
              favourite={isFavourite}
              onClick={onChangeFavourite}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationsOverlay;
