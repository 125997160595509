import { useQuery } from '@apollo/client';
import { GET_NOTIFICATION_META } from '../../graphql/notifications/notifications.quries';
import { NotificationType, Query } from '../generated/graphql';
import FeatureFlagUtil from '../FeatureFlagUtil';
import { selectRoles } from '../../redux/reducers/settingsReducer';
import { useSelector } from 'react-redux';

export const useGetNotificationMeta = (skipCondition: boolean) => {
  const { data, loading, error } = useQuery<Pick<Query, 'notificationTypes'>>(
    GET_NOTIFICATION_META,
    {
      skip: skipCondition,
    }
  );
  const roles = useSelector(selectRoles);

  const isRoleDisabled = !FeatureFlagUtil.showFeature(
    process?.env?.REACT_APP_TAC_ROLE_NOTIFICATIONS_FEATURE_DISABLED ?? '',
    roles
  );

  const filteredRoles = data?.notificationTypes?.types?.filter(
    (type) => type.type !== NotificationType.ROLE
  );

  return {
    data: {
      ...data?.notificationTypes,
      types: isRoleDisabled ? filteredRoles : data?.notificationTypes?.types,
    },
    loading,
    error,
  };
};
