import { Role, RoleGroup } from './generated/graphql';

class FeatureFlagUtil {
  static showFeature(featureFlag: string, userRoles: Role[]) {
    if (!featureFlag) {
      return true;
    }

    if (featureFlag.toUpperCase() === 'BETA') {
      return userRoles.some((role) => role.roleGroup === RoleGroup.BETA);
    }

    if (featureFlag.toUpperCase() === 'TRUE') {
      return false;
    }

    return true;
  }
}

export default FeatureFlagUtil;
