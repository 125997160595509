import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Notification } from '../Newsfeed/NotificationAccordion/NotificationAccordion';
import { formatNotificationsDescription } from '../../utils/notifications/notificationsUtil';
import { Notification as NotificationType } from '../../utils/generated/graphql';
import { getWindowDimensions } from '../../utils/helpers';
import { MOBILE_NOTIFICATION_BREAKPOINT } from '../../utils/constants';
import PulseNotificationIcon from '../PulseNotificationIcon/PulseNotificationIcon';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';

interface IFlyInNotification {
  notification: NotificationType;
  isCheckedIn?: boolean;
  use24Format: boolean;
  useUTC: boolean;
}

const FlyInNotification = ({
  notification,
  isCheckedIn = false,
  use24Format,
  useUTC,
}: IFlyInNotification) => {
  const navigate = useNavigate();
  const isMobileNotification =
    getWindowDimensions().width <= MOBILE_NOTIFICATION_BREAKPOINT;

  return (
    <div
      onClick={() =>
        notification.details.flightId
          ? navigate(`/favourites/${notification.details.flightId}/process`)
          : null
      }>
      <div
        className={classNames('flex leading-[19px] min-w-[259px]', {
          'flex-col': !isMobileNotification,
          'flex-row': isMobileNotification,
        })}>
        <div
          className={
            'w-[105px] whitespace-nowrap mobile:self-center font-head-bold text-18 text-primary dark:text-white cursor-pointer flex gap-8 items-center'
          }>
          {notification.title}
          {FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
            []
          ) &&
            isCheckedIn && <PulseNotificationIcon />}
        </div>
        {!isMobileNotification ? (
          <Notification
            time="now"
            subtitle={notification.subtitle}
            text={formatNotificationsDescription(notification, {
              use24Format,
              useUTC,
            })}
            isNewNotification={false}
            isExpanded={false}
            isFlyinNotification={true}
          />
        ) : (
          <div className="flex items-center pl-[25px]">
            <h1 className="whitespace-nowrap pl-[6px] py-[10px] self-end text-grey-80">
              {notification.subtitle}
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlyInNotification;
