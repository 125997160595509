import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../components/Hero/Hero';
import MyLinks from '../../components/MyLinks/MyLinks';
import Newsfeed from '../../components/Newsfeed/Newsfeed';
import {
  selectFirstName,
  updateUserSettingsSuccess,
  selectSettingsData,
  selectStationsList,
} from '../../redux/reducers/settingsReducer';
import {
  ERRORS,
  FLIGHT_STATUS,
  GO_TO_SETTINGS,
  MISSING_PHONE_NUMBER,
  MISSING_PHONE_NUMBER_COPY,
  NO_THANK_YOU,
  REMIND_ME_LATER,
} from '../../utils/constants';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import {
  selectAuthReducer,
  selectIsFirstLogin,
  selectUserId,
  setIsFirstLogin,
} from '../../redux/reducers/authReducer';
import { useNotifications } from '../../utils/hooks/useNotifications';
import {
  selectAllNotifications,
  selectShouldFetchNotifications,
} from '../../redux/reducers/newsfeedReducer';
import {
  handleNotificationTimeSort,
  handleTimeSort,
} from '../../utils/notifications/notificationsUtil';
import { useGetMyFlightsCached } from '../../utils/hooks/useGetMyFlightsCached';
import { useGetMyFlightsLazy } from '../../utils/hooks/useGetMyFlights';
import {
  Permission,
  UpdateSettingsLocations,
} from '../../utils/generated/graphql';
import { checkCUTEDevice } from '../../utils/helpers';
import useCheckPermissions from '../../utils/hooks/useCheckPermissions';
import Modal from '../../components/Modal/Modal';
import { useGetUserSettings } from '../../utils/hooks/useGetUserSettings';
import { useGetNotificationsHistory } from '../../utils/hooks/useGetNotificationsHisotry';
import Button from '../../components/Button/Button';
import { useUpdateUserSettings } from '../../utils/hooks/useUpdateUserSettings';
import useCheckTechnicalRole from '../../utils/hooks/useCheckRolePermissions';
import { useGetTelephonyBook } from '../../utils/hooks/useGetTelephonyBook';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import { setStation } from '../../redux/reducers/telephoneBookReducer';
import { AppDispatch } from '../../redux/store';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';

const Home = () => {
  const name = useSelector(selectFirstName);
  const userId = useSelector(selectUserId);
  const notifications = useSelector(selectAllNotifications);
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isFirstLogin = useSelector(selectIsFirstLogin);
  const shouldFetchNotification = useSelector(selectShouldFetchNotifications);
  const tokenPhone = useSelector(selectAuthReducer).tokenPhone;
  const navigate = useNavigate();
  const { getNotificationsHistory } = useGetNotificationsHistory();
  const { data: settingsData, loading: isLoadingSettings } =
    useGetUserSettings();
  const { onUpdateUserSettings } = useUpdateUserSettings();
  const isOnline = useNetworkStatus();

  const isCockpit = useCheckTechnicalRole('TAC_LH_COCKPIT');

  const userStations = useSelector(selectStationsList);

  const stationsList = useMemo(() => {
    return isCockpit ? [] : ['General', ...userStations];
  }, [isCockpit, userStations]);
  const { data: dataStation, loading: loadingStation } =
    useGetTelephonyBook(stationsList);

  useEffect(() => {
    if (!isLoadingSettings && !settingsData) {
      navigate(`/settings`);
    }
  }, [isLoadingSettings, navigate, settingsData]);

  useEffect(() => {
    const isLoading = isOnline && loadingStation;
    if (!isLoading && dataStation) {
      dispatch(setStation(dataStation));
    }
  }, [dataStation, dispatch, isOnline, loadingStation, stationsList]);

  const checkPhoneReminder = settingsData?.isPhoneReminderActive;

  const {
    isFormError,
    error,
    roles,
    isLoading,
    isLoginFlowComplete,
    ...storeSettingsData
  } = useSelector(selectSettingsData);

  const buttonModalClass =
    'font-head-bold w-[158px] py-13 justify-center rounded-4 flex items-center h-44 bg-primary dark:bg-grey-12 m-2 mobile:w-full';

  const textButtonModalClass = 'text-white text-14 dark:text-grey-90  pl-[8px]';

  useEffect(() => {
    if (shouldFetchNotification) {
      getNotificationsHistory();
    }
  }, [dispatch, shouldFetchNotification]);

  useEffect(() => {
    const hasRegisteredPhone = !!settingsData?.phoneNumber || !!tokenPhone;
    if (
      isFirstLogin &&
      !isLoadingSettings &&
      !hasRegisteredPhone &&
      checkPhoneReminder
    ) {
      setIsModalOpen(true);
    }
    if (isFirstLogin && isModalOpen) {
      dispatch(setIsFirstLogin(false));
    }
  }, [
    isFirstLogin,
    settingsData?.phoneNumber,
    tokenPhone,
    isLoadingSettings,
    checkPhoneReminder,
  ]);

  const handlePhoneReminder = async (isPhoneReminderActive: boolean) => {
    const data = {
      ...storeSettingsData,
      isPhoneReminderActive: isPhoneReminderActive,
    };

    const result = await onUpdateUserSettings(
      data,
      UpdateSettingsLocations.HOME
    );

    result &&
      dispatch(updateUserSettingsSuccess(result)) &&
      setIsModalOpen(false);
  };

  const handleRedirectToSettings = () => {
    setIsModalOpen(false);
    navigate('/settings');
  };

  const hasNotificationAccess = useCheckPermissions([
    Permission.DISPLAY_NOTIFICATIONS,
  ]);

  const hasLinksAccess = useCheckPermissions([
    Permission.DISPLAY_HOMEPAGE_LINKS,
  ]);

  const sortedNotifications = useMemo(() => {
    const sortedInnerNotification = handleNotificationTimeSort(
      notifications,
      'time-ASC'
    );
    return handleTimeSort(sortedInnerNotification, 'time-ASC');
  }, [notifications]);

  const { data, error: getMyFlightError } = useGetMyFlightsCached(userId);
  useNotifications(getMyFlightError, ERRORS.NO_FAVOURITE_FLIGHTS);

  useCustomHeader({ headerTypeMobile: 'simple', title: '' });
  const { data: myNextFlight, getMyFlights } = useGetMyFlightsLazy();

  useEffect(() => {
    if (data && data.length >= 1) {
      getMyFlights({ flightId: data[0].flightId });
    }
  }, [data]);

  const myNextFavouriteFlight = myNextFlight?.find(
    (flight) => flight.flightStatus === FLIGHT_STATUS.DEFAULT
  );
  const isCUTEDevice = checkCUTEDevice();
  return (
    <div
      id="home-page"
      className="ml-24 pb-24 mr-40 mobile:mr-24 mobile:pt-24 tablet:grid gap-[40px] tablet:grid-cols-3 laptop:grid laptop:grid-cols-3 desktop:grid desktop:grid-cols-3">
      <div className="tablet:col-span-2 laptop:col-span-2 desktop:col-span-2 mt-[58px]">
        <Hero
          name={name}
          nextFavouriteFlight={myNextFavouriteFlight || null}
          hasFavourites={!!myNextFavouriteFlight}
        />
        {!isCUTEDevice && hasLinksAccess && (
          <>
            <h1 className="flex items-center h-[58px] font-head-light text-grey-40 text-14 leading-[18px]">
              My Links
            </h1>
            <MyLinks linkArray={['LH', 'LX', 'DSM', 'FLT', 'L', 'FEEDBACK']} />
          </>
        )}
      </div>
      {hasNotificationAccess && (
        <Newsfeed
          isFlightSpecific={false}
          isOverlay={false}
          notificationsList={Object.values(sortedNotifications || [])}
          notificationsDisabled={
            !FeatureFlagUtil.showFeature(
              process?.env?.REACT_APP_TAC_NOTIFICATIONS_FEATURE_DISABLED ?? '',
              []
            )
          }
          myFlights={data ?? []}
        />
      )}
      <Modal
        title={MISSING_PHONE_NUMBER}
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        exitButton={false}
        showControls={false}>
        <div className="mb-5">
          <p className="m-2">{MISSING_PHONE_NUMBER_COPY}</p>
          <div className="flex flex-row mobile:flex-col items-center">
            <Button
              text={GO_TO_SETTINGS}
              textClassName={textButtonModalClass}
              className={buttonModalClass}
              onClick={handleRedirectToSettings}
            />
            <Button
              text={NO_THANK_YOU}
              textClassName={textButtonModalClass}
              className={buttonModalClass}
              onClick={() => handlePhoneReminder(false)}
            />
            <Button
              text={REMIND_ME_LATER}
              textClassName={textButtonModalClass}
              className={buttonModalClass}
              onClick={() => handlePhoneReminder(true)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
