import { useMutation } from '@apollo/client';
import {
  MessageStatus,
  Mutation,
  SendChatMessageInput,
  SendChatMessageOutput,
} from '../generated/graphql';
import { SEND_MESSAGE } from '../../graphql/chat/send-message.mutations';
import { IMutationResponse } from '../types';

interface SendChatMessageResponse
  extends IMutationResponse<SendChatMessageOutput> {
  onSendChatMessage: (
    data: SendChatMessageInput,
    manualRetry?: boolean
  ) => Promise<SendChatMessageOutput | null>;
}

const MAX_RETRIES =
  parseInt(process?.env?.REACT_APP_TAC_CHAT_MESSAGE_MAX_RETRIES || '', 10) || 3;

export const useSendChatMessage = (): SendChatMessageResponse => {
  const [sendChatMessage, { data, error }] = useMutation<
    Pick<Mutation, 'sendChatMessage'>
  >(SEND_MESSAGE, { fetchPolicy: 'no-cache' });

  const onSendChatMessage = async (
    data: SendChatMessageInput,
    manualRetry: boolean = false,
    retryCount: number = 1
  ) => {
    try {
      const response = await sendChatMessage({
        variables: {
          input: data,
        },
      });

      const shouldRetry =
        !manualRetry &&
        retryCount < MAX_RETRIES &&
        (response?.data?.sendChatMessage?.errors?.length ||
          response?.data?.sendChatMessage?.status === MessageStatus.FAILED);

      if (shouldRetry) {
        return onSendChatMessage(data, manualRetry, retryCount + 1);
      }
      return response?.data?.sendChatMessage ?? null;
    } catch (error) {
      if (!manualRetry && retryCount < MAX_RETRIES) {
        return onSendChatMessage(data, manualRetry, retryCount + 1);
      } else {
        return null;
      }
    }
  };
  return {
    onSendChatMessage,
    data: data?.sendChatMessage ?? null,
    error,
  };
};
